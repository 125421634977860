import Chat from 'assets/widget/chat.svg';
import Community from 'assets/widget/community.svg';
import PostLanding from 'assets/widget/postlanding.svg';

export default {
  widgets: [
    {
      id: 1,
      iconSrc: Chat,
      altText: 'Community',
      title: 'Join the Community',
      description:
        'Be a part of Noah Students Community and lets grow together.',
    },
    {
      id: 2,
      iconSrc: Community,
      altText: 'Chat',
      title: 'Personal Support',
      description:
        'Get Personalised Support for all your study abroad needs.',
    },
    {
      id: 3,
      iconSrc: PostLanding,
      altText: 'Career & Migration Support',
      title: 'Career & Migration Support',
      description:
        'Get consultation for migration and finding career abroad.',
    },
  ],
  menuItem: [
    {
      path: '/',
      label: 'Home',
    },
    {
      path: '/',
      label: 'About',
    },
    {
      path: '/',
      label: 'Career Mentorship',
    },
    {
      path: '/',
      label: 'Post Landing Services',
    },
    {
      path: '/',
      label: 'Support',
    },
    {
      path: '/',
      label: 'Contact',
    },
  ],
};
