export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'Features',
  },
  {
    path: 'feature',
    label: 'Services',
  },
  {
    path: 'pricing',
    label: 'Consultation',
  },
  {
    path: 'pricing',
    label: 'Team',
  },
  {
    path: 'testimonial',
    label: 'Support',
  },
];
